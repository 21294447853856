<template>
	<div class="diy_edit page_message_box" id="message_box_edit">
		<div class='warp'>
			<div class='container'>
				<div class='row'>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>日期:
							</span>
						</div>
						<!-- 日期 -->
						<div class="diy_field diy_date">
							<input type="date" :disabled="disabledObj['date_isDisabled']" id="form_date" v-model="form['date']" placeholder="请输入日期" v-if="(form['date'] && $check_field('set','date')) || (!form['date'] && $check_field('add','date'))" />
							<span v-else-if="$check_field('get','date')">{{ form[''] }}</span>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>内容:
							</span>
						</div>
						<!-- 多文本 -->
						<div class="diy_field diy_desc">
							<textarea id="form_content" v-model="form['content']" v-if="(form['content'] && $check_field('set','content')) || (!form['content'] && $check_field('add','content'))" :disabled="disabledObj['content_isDisabled']" />
							<span v-else-if="$check_field('get','content')">{{ form[''] }}</span>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="diy_title">
							<span>
								审核状态:
							</span>
						</div>
						<div class="diy_field diy_select" v-if="$check_action('/message_box/edit','examine')">
							<span> {{ form['examine_state'] }} </span>
							<!-- <select v-model="form['examine_state']">
								<option value="未审核">
									未审核
								</option>
								<option value="已通过">
									已通过
								</option>
								<option value="未通过">
									未通过
								</option>
							</select> -->
						</div>
						<div class="diy_field diy_text" v-else>
							<span>
								{{ form['examine_state'] }}
							</span>
						</div>
					</div>
					<div class="col-12 col-md-6" >
						<div class="diy_title">
							<span>
								审核回复:
							</span>
						</div>
						<div class="diy_field diy_desc" v-if="$check_action('/message_box/edit','examine')">
							<textarea v-model="form['examine_reply']"></textarea>
						</div>
						<div class="diy_field diy_text" v-else>
							<span>
								{{ form['examine_reply'] }}
							</span>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="btn_box">
							<button class="btn_submit" @click="submit()">提交</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixin from "@/mixins/page.js";
	export default {
		mixins: [mixin],
		components: {},
		data() {
			return {
				url_get_obj: "~/api/message_box/get_obj?",
				url_add: "~/api/message_box/add?",
				url_set: "~/api/message_box/set?",

				// 登录权限
				oauth: {
					"signIn": true,
					"user_group": []
				},

				// 查询条件
				query: {
					"date": "",
					"content": "",
					"message_box_id": 0
				},

				obj: {
					"date": "1970-01-01 00:00:00",
					"content":'', // 内容
					"examine_state": "未审核",
					"examine_reply": "",
					"message_box_id": 0
				},

				// 表单字段
				form: {
					"date": "1970-01-01 00:00:00",
					"content":'', // 内容
					"examine_state": "未审核",
					"examine_reply": "",
					"message_box_id": 0
				},
				disabledObj:{
					"date_isDisabled": false,
					"content_isDisabled": false,
				},

				// ID字段
				field: "message_box_id"
			}
		},
		methods: {

			/**
			 * 修改文件
			 * @param { Object } files 上传文件对象
			 * @param { String } str 表单的属性名
			 */
			change_file(files, str) {
				var form = new FormData();
				form.append("file", files[0]);
				this.$post("~/api/message_box/upload?", form, (res) => {
					if (res.result) {
						this.form[str] = res.result.url;
					} else if (res.error) {
						this.$toast(res.error.message);
					}
				});
			},

			/**
			 * 获取对象后获取缓存表单
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_before(param){
				var form = $.db.get("form");
				if (form) {
					this.obj = $.push(this.obj ,form);
					this.form = $.push(this.form ,form);
				}
				var arr = []
				for (let key in form) {
					arr.push(key)
				}
				for (var i=0;i<arr.length;i++){
					this.disabledObj[arr[i] + '_isDisabled'] = true
				}
				return param;
			},

			/**
			 * 获取对象后获取缓存表单
			 * @param {Object} json
			 * @param {Object} func
			 */
			get_obj_after(json ,func){
				var form = $.db.get("form");
				var obj = Object.assign({} ,form ,this.obj);
				if (form) {
					this.obj = $.push(this.obj ,obj);
				}
				if (form) {
					this.form = $.push(this.form ,form);
				}
				if(func){
					func(json);
				}
			}

		},
		created() {
		}
	}
</script>

<style>
</style>
